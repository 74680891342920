import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import Select from "react-select";
import Loader from "../../../components/loader";
import axios from "axios";
import Cookies from "js-cookie";
import buildLink from "../../../urls";
import MessageAlert from "../../../components/messageAlert";
import { PlaySounds } from "../../../functions/playSounds";

const Warehousetransfer = () => {
  const history = useNavigate();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [inputProductId, setInputProductId] = useState("");
  const [destinationWarehouse, setDestinationWarehouse] = useState(null);
  const [inputQuantity, setInputQuantity] = useState("");
  const [scanProductId, setScanProductId] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [loadingWarehouses, setLoadingWarehouses] = useState(true);
  const [error, setError] = useState(null);
  const [FromWarehouse, setFromWarehouse] = useState([]);
  const [selectedWarehouseto, setSelectedWarehouseto] = useState(null);
  const [selectedWarehousefrom, setSelectedWarehousefrom] = useState(null);
  const [showMessgErr, setShowMessageErr] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessgSucc, setShowMessageSucc] = useState(false);
  const { playErrorSound, playSuccessSound } = PlaySounds();
  const [logEntries, setLogEntries] = useState([]);

  const fetchfromwarehouse = async () => {
    try {
      const url = buildLink(`fetchfromwarehouse`);
      const response = await axios.get(url);

      if (response.data.success) {
        setWarehouses(response.data.data || []);
        setLoadingWarehouses(false);
      } else {
        setError("Failed to fetch warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  useEffect(() => {
    fetchfromwarehouse();

    const savedFromWarehouse = Cookies.get("fromWarehouse");
    const savedToWarehouse = Cookies.get("toWarehouse");

    if (savedFromWarehouse) {
      setSelectedWarehousefrom(JSON.parse(savedFromWarehouse));
    }
    if (savedToWarehouse) {
      setSelectedWarehouseto(JSON.parse(savedToWarehouse));
    }
  }, []);

  const getUserIdFromLocalStorage = () => {
    return localStorage.getItem("userID");
  };

  const handleFromWarehouseChange = (selectedOption) => {
    setSelectedWarehousefrom(selectedOption);

    Cookies.set("fromWarehouse", JSON.stringify(selectedOption), {
      expires: 1,
    });

    setSelectedWarehouseto(null);

    fetchtowarehouse(selectedOption);
  };

  const fetchtowarehouse = async (e) => {
    const warehouseId = e.value;
    setSelectedWarehousefrom(warehouseId);
    setSelectedWarehouseto(null);

    try {
      const url = buildLink(`towarehouse`) + `&warehouse_id=${warehouseId}`;
      const response = await axios.get(url);

      if (response.data.success) {
        setFromWarehouse(response.data.data || []);
      } else {
        setError("Failed to fetch from warehouses.");
      }
    } catch (err) {
      setError("Failed to fetch from warehouses.");
    } finally {
      setLoadingWarehouses(false);
    }
  };

  const handleToWarehouseChange = (option) => {
    setSelectedWarehouseto(option);
    Cookies.set("toWarehouse", JSON.stringify(option), { expires: 1 });
  };

  const submitwarehousechange = async () => {
    const UserId = getUserIdFromLocalStorage();

    if (!inputProductId) {
      setMessage("Enter Product ID OR SKU");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      return;
    }

    const fromWarehouseId =
      selectedWarehousefrom?.value ||
      JSON.parse(Cookies.get("fromWarehouse"))?.value;
    const toWarehouseId =
      selectedWarehouseto?.value ||
      JSON.parse(Cookies.get("toWarehouse"))?.value;

    if (!fromWarehouseId || !toWarehouseId) {
      setMessage("Please select both source and destination warehouses.");
      setShowMessageErr(true);
      setTimeout(() => {
        setShowMessageErr(false);
      }, 3000);
      return;
    }

    const obj = {
      from_warehouse: fromWarehouseId,
      to_warehouse: toWarehouseId,
      product_id: inputProductId,
      quantity: inputQuantity,
      user_id: UserId,
    };

    try {
      const response = await axios.post(
        buildLink("submitwarehousechange"),
        obj
      );
      if (response.data.success) {
        setMessage(response.data.message);
        setShowMessageSucc(true);
        playSuccessSound();
        setInputProductId("");
        setInputQuantity("");
        // setSelectedWarehousefrom(null);
        // setSelectedWarehouseto(null);
        setFromWarehouse([]);
        setTimeout(() => {
          setShowMessageSucc(false);
        }, 3000);
        setLogEntries([
          ...logEntries,
          { productId: inputProductId, quantity: inputQuantity },
        ]);
      } else {
        setMessage(response.data.message);
        setShowMessageErr(true);
        playErrorSound();
        setTimeout(() => {
          setShowMessageErr(false);
        }, 3000);
        setInputQuantity("");
        setInputProductId("");
      }
    } catch (err) {
      console.log("An error occurred during the transfer.", err);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="mb-5 sticky top-0 flex flex-col bg-white">
        <div className="w-full bg-dbase h-16">
          <div className="container px-3 relative flex justify-between w-full text-white h-full">
            <div className="flex gap-7 flex-row justify-start">
              <button onClick={() => history(-1)}>
                <FaArrowLeft />
              </button>
              <h2 className="text-xl my-auto">Warehouse Transfer</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-5">
        <h3 className="text-lg font-semibold mb-3">Transfer Product</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitwarehousechange();
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="py-3">
              <div className="mb-1">From Warehouse</div>
              {loadingWarehouses ? (
                <div className="text-center text-dbase">
                  <Loader />
                </div>
              ) : error ? (
                <p className="text-dbase">{error}</p>
              ) : (
                <Select
                  placeholder="Source Warehouse"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "7px",
                      borderColor: state.isFocused ? "grey" : "grey",
                      padding: "8px 5px",
                    }),
                  }}
                  options={
                    warehouses.length > 0
                      ? warehouses.map((warehouse) => ({
                          value: warehouse.warehouse_id,
                          label: warehouse.warehouse_name,
                        }))
                      : []
                  }
                  onChange={handleFromWarehouseChange}
                  defaultValue={selectedWarehousefrom}
                />
              )}
            </div>

            <div className="py-3">
              <div className="mb-1">To Warehouse</div>
              {loadingWarehouses ? (
                <div className="text-center text-dbase">
                  <Loader />
                </div>
              ) : error ? (
                <p className="text-dbase">{error}</p>
              ) : (
                <Select
                  placeholder="Destination Warehouse"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "7px",
                      borderColor: state.isFocused ? "grey" : "grey",
                      padding: "8px 5px",
                    }),
                  }}
                  options={
                    FromWarehouse.length > 0
                      ? FromWarehouse.map((warehouse) => ({
                          value: warehouse.warehouse_id,
                          label: warehouse.warehouse_name,
                        }))
                      : []
                  }
                  value={selectedWarehouseto}
                  onChange={handleToWarehouseChange}
                />
              )}
            </div>

            <div className="py-3">
              <div className="mb-1">Product</div>
              <input
                type="text"
                value={inputProductId}
                onChange={(e) => setInputProductId(e.target.value)}
                placeholder="Product ID Or SKU"
                className="border p-2 rounded-md w-full"
                required
              />
            </div>

            <div className="py-3">
              <div className="mb-1">Quantity</div>
              <input
                type="number"
                value={inputQuantity}
                onChange={(e) => setInputQuantity(e.target.value)}
                placeholder="Quantity to Transfer"
                className="border p-2 rounded-md w-full"
                required
              />
            </div>

            <button
              type="submit"
              className="bg-dbase rounded-md text-white px-5 py-3 flex justify-center items-center"
            >
              {loadingSearch ? <Loader /> : <FaSearch />}
            </button>
          </div>
        </form>
      </div>

      <MessageAlert
        message={message}
        type={showMessgErr ? "err" : "succ"}
        showMessg={showMessgErr || (showMessgSucc && true)}
      />

      <div className="mt-5 px-6 pb-2">
        <h3 className="text-lg font-semibold mb-3">Transfer Log</h3>
        <table className="w-full  table-auto border-collapse border">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 border-r">Product ID</th>
              <th className="px-4 py-2">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {logEntries.map((entry, index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2 text-center border-r">
                  {entry.productId}
                </td>
                <td className="px-4 py-2 text-center">{entry.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Warehousetransfer;
