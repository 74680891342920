import React from "react";
import NavigateButton from "../../../components/buttonsNavigate";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaHistory,
  FaListUl,
  FaSearch,
  FaShoppingCart,
  FaSyncAlt,
} from "react-icons/fa";
import { FaMapMarkerAlt, FaEdit } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { LiaTruckPickupSolid } from "react-icons/lia";
import { TbTransfer } from "react-icons/tb";
import { FaWarehouse } from "react-icons/fa";

import { LuBoxes } from "react-icons/lu";
import { BsDatabaseFillCheck } from "react-icons/bs";
import { FaProductHunt } from "react-icons/fa6";
import { HiStatusOffline } from "react-icons/hi";

const WareHouse = () => {
    const colors = [
        "bg-[#BF1B26]",
        "bg-[#0D6E99]",
        "bg-[#5E2D79]",
        "bg-[#4CAF50]",
        "bg-[#333333]",
        "bg-[#CCCCCC]",
        "bg-[#FF5733]",
        "bg-[#FFC300]",
        "bg-[#581845]",
        "bg-[#FF851B]",
        "bg-[#7D3C98]",
        "bg-[#01A9DB]",
        "bg-[#7FF050]",
        "bg-[#FF007F]",
        "bg-[#ADD8E6]",
        "bg-[#7EE050]",
      ];
  const history = useNavigate();
  return (
    <>
      {/* header */}
      <div className=" mb-5 sticky top-0 flex flex-col bg-white">
        <div className=" w-full bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl    my-auto">Warehouse</h2>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-2 container gap-x-6 gap-y-4">
        <>
        <NavigateButton
            icon={<FaWarehouse />}
            name={"Warehouse Products"}
            color={colors[15]}
            onclick={() => history("WarehouseProduct")}
          />
          <NavigateButton
            icon={<FaMapMarkerAlt />}
            name={"Change Product Address"}
            color={colors[1]}
            onclick={() => history("changeproductaddress")}
          />
          <NavigateButton
            icon={<TbTransfer />}
            name={"Warehouse Transfer"}
            color={"bg-[rgb(145,170,0)]"}
            onclick={() => history("warehousetransfer")}
          />
                          <NavigateButton
                icon={<FaSyncAlt />}
                name={"Change Status"}
                color={"bg-[rgb(252,186,3)] bg-opacity-90"}
                onclick={() => history("ChangeStatusWarehouse")}
              />
        </>
      </div>
    </>
  );
};

export default WareHouse;
