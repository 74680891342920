import React from "react";
import NavigateButton from "../../../components/buttonsNavigate";
import {
  FaArrowLeft,
  FaSyncAlt,
} from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { TbTransfer } from "react-icons/tb";
import { FaWarehouse } from "react-icons/fa";
import { BsSendArrowDownFill } from "react-icons/bs";
import { MdCallReceived } from "react-icons/md";
import { GrCompliance } from "react-icons/gr";


const ChangeStatusWarehouse = () => {
    
  const history = useNavigate();
  return (
    <>
      {/* header */}
      <div className=" mb-5 sticky top-0 flex flex-col bg-white">
        <div className=" w-full bg-dbase h-16">
          <div className=" container flex relative gap-7 flex-row justify-start w-full text-white  h-full my-auto">
            <button onClick={() => history(-1)}>
              <FaArrowLeft />
            </button>
            <h2 className=" text-xl    my-auto">Change Status</h2>
          </div>
        </div>
      </div>
      <div className=" grid grid-cols-2 container gap-x-6 gap-y-4">
        <>
        <NavigateButton
            icon={<BsSendArrowDownFill />}
            name={"Send To Warehouse"}
            color={"bg-[rgb(255,0,230)]"}
            onclick={() => history("Send To Warehouse")}
          />
          <NavigateButton
            icon={<MdCallReceived />}
            name={"Received From Warehouse"}
            color={"bg-[rgb(0,0,255)]"}
            onclick={() => history("Received From Warehouse")}
          />
         <NavigateButton
            icon={<GrCompliance />}
            name={"Restock"}
            color={"bg-[rgb(0,0,0)]"}
            onclick={() => history("Warehouse Restock")}
          />

        </>
      </div>
    </>
  );
};

export default ChangeStatusWarehouse;
