import React, { useRef, useState } from "react";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MessageAlert from "../../../components/messageAlert";
import Loader from "../../../components/loader";
import axios from "axios";
import buildLink from "../../../urls";
import { PlaySounds } from "../../../functions/playSounds";
const WarehouseProduct = () => {
    const { playErrorSound, playSuccessSound } = PlaySounds();

    const history = useNavigate();
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [WahrehouesInfo, setWahrehouesInfo] = useState([]);
    const [error, setError] = useState(null);
    const productId = useRef(null);
    const [systemQuantity, setSystemQuantity] = useState(null);
    const [message, setMessage] = useState("");
    const [showMessgSucc, setShowMessageSucc] = useState(false);
    const [showMessgErr, setShowMessageErr] = useState(false);

    const getProducts = async (e) => {
        e.preventDefault();
        setLoadingSearch(true);
        setError(null);
        try {
            const url = buildLink(`getproductsinwarehouse`) + `&product_id=${productId.current.value}`;
            const response = await axios.get(url);
            if (response.data.success) {
                setWahrehouesInfo(response.data.warehouses);
                setSystemQuantity(response.data.system_quantity);
                setMessage(response.data.message);
                setShowMessageSucc(true);
                playSuccessSound();
                setTimeout(() => {
                    setShowMessageSucc(false);
                }, 3000);

            } else {
                setMessage(response.data.message);
                setShowMessageErr(true);
                setTimeout(() => {
                    setShowMessageErr(false);
                }, 3000);
                playErrorSound();
                setWahrehouesInfo([]);
            }
        } catch (e) {
            setShowMessageErr(true);
            setTimeout(() => {
                setShowMessageErr(false);
            }, 3000);
            playErrorSound();
        } finally {
            setLoadingSearch(false);
        }
    };

    return (
        <>
            {/* Header */}
            <div className="mb-5 sticky top-0 flex flex-col bg-white">
                <div className="w-full bg-dbase h-16">
                    <div className="container px-3 relative flex justify-between w-full text-white h-full">
                        <div className="flex gap-7 flex-row justify-start">
                            <button onClick={() => history(-1)}>
                                <FaArrowLeft />
                            </button>
                            <h2 className="text-xl my-auto">Warehouse Products</h2>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={getProducts}>
                <div className="flex flex-row border-dblack rounded-lg text-center w-full gap-3 px-4 md:px-12 lg:px-32">
                    <input
                        ref={productId}
                        type="text"
                        className="border border-dblack rounded-lg block p-2.5 flex-grow text-start w-full"
                        placeholder="Product Id OR SKU"
                    />
                    <button className="border border-dblack bg-dbase rounded-lg text-white px-5">
                        {loadingSearch ? <Loader /> : <FaSearch />}
                    </button>
                </div>
            </form>

            {systemQuantity && (
                <div className="text-center text-xl my-5">
                    System Quantity: {systemQuantity}
                </div>
            )}

            {WahrehouesInfo.length > 0 && (
                <div className="overflow-x-auto mt-5 px-4 md:px-12 lg:px-32">
                    <table className="min-w-full bg-white border border-dgreySeller">
                        <thead>
                            <tr className="bg-dgrey text-dblackk">
                                <th className="border px-4 py-2">Warehouse Name</th>
                                <th className="border px-4 py-2">Quantity</th>
                                <th className="border px-4 py-2">Address</th>
                                <th className="border px-4 py-2">Reference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {WahrehouesInfo.map((warehouse, index) => (
                                <tr key={index} className="hover:bg-gray-100">
                                    <td className="border px-4 py-2 text-center">{warehouse.warehouse_name}</td>
                                    <td className="border px-4 py-2 text-center">{warehouse.quantity}</td>
                                    <td className="border px-4 py-2 text-center">{warehouse.address_id}</td>
                                    <td className="border px-4 py-2 text-center">{warehouse.reference}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <MessageAlert
                message={message}
                type={showMessgErr ? "err" : "succ"}
                showMessg={showMessgErr || (showMessgSucc && true)}
            />
        </>
    );
};

export default WarehouseProduct;
